type TPostSchema = {
  title: string;
  description: string;
  image: string;
  author: string;
  path: string;
  publishDate: string;
};

type FreightChildrenPagesParams = {
  title: string;
  path: string;
};

const oldSchema = (title: string, description: string, path: string) => `{
    "@context": "http://www.schema.org",
    "@type": "Organization",
    "name": "${title} | Digital Freight Alliance",
    "url": "https://www.df-alliance.com${path}",
    "logo": "https://content.presspage.com/uploads/2451/1920_dfalliancelogo.jpg",
    "image": "https://content.presspage.com/uploads/2451/1920_dfalliancelogo.jpg",
    "description": "${description}"
  }`;

const newSchema = (title: string, description: string, path: string, image: string, name: string) => `{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "@id": "https://www.df-alliance.com${path}",
  "alternateName": "${title} | Digital Freight Alliance",
  "description": "${description}",
  ${image && `"image": "https://www.df-alliance.com${image}",`}
  "inLanguage": "English",
  "isPartOf": "https://www.df-alliance.com/",
  "sameAs": [
    "https://www.facebook.com/DigitalFreightAlliance_DPWorld",
    "https://twitter.com/D_F_Alliance",
    "https://www.linkedin.com/company/digital-freight-alliance"
  ],
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": {
          "@id": "https://www.df-alliance.com/"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "${name}",
        "item": {
          "@id": "https://www.df-alliance.com${path}"
        }
      }
    ]
  }
}`;

const FreightDictionarySchema = (title: string, description: string, path: string, image: string, name: string) => `{
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "item": {
        "@id": "https://www.df-alliance.com/",
        "name": "Home"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "item": {
        "@id": "https://www.df-alliance.com/freight-dictionary",
        "name": "${title}"
      }
    }
  ]
}`;

const FreightDictionaryChildrenSchema = ({ title, path }: FreightChildrenPagesParams) => `{
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "item": {
        "@id": "https://www.df-alliance.com/",
        "name": "Home"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "item": {
        "@id": "https://www.df-alliance.com/freight-dictionary",
        "name": "Freight Dictionary | All Freight Terms with Explanations - DF Alliance"
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "item": {
        "@id": "https://www.df-alliance.com${path}",
        "name": "${title}"
      }
    }
  ]
}`;

const FreightForwarderSchema = (title: string, description: string, path: string, image: string, name: string) => `{
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "item": {
        "@id": "https://www.df-alliance.com/",
        "name": "Home"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "item": {
        "@id": "https://www.df-alliance.com/freight-forwarder",
        "name": "${title}"
      }
    }
  ]
}`;

const FreightForwarderChildrenSchema = ({ title, path }: FreightChildrenPagesParams) => `{
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "item": {
        "@id": "https://www.df-alliance.com/",
        "name": "Home"
      }
    },
    {
      "@type": "ListItem",
      "position": 2,
      "item": {
        "@id": "https://www.df-alliance.com/freight-forwarder",
        "name": "How to Become a Freight Forwarder | DF Alliance"
      }
    },
    {
      "@type": "ListItem",
      "position": 3,
      "item": {
        "@id": "https://www.df-alliance.com${path}",
        "name": "${title}"
      }
    }
  ]
}`;

const postSchema = ({ title, description, image, author, path, publishDate }: TPostSchema) => `
{
  "@context": "https://schema.org",
  "@type": "NewsArticle",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://www.df-alliance.com${path}"
  },
  "headline": "${title}",
  "description": "${description}",
  "image": "https://www.df-alliance.com${image}",
  "author": {
    "@type": "Person",
    "name": "${author}"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "https://www.df-alliance.com/",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.df-alliance.com/images/global/logo-dfa-light.svg"
    }
  },
  "datePublished": "${publishDate}",
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": {
          "@id": "https://www.df-alliance.com/"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Blogs & Updates",
        "item": {
          "@id": "https://www.df-alliance.com/blog"
        }
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "${title}}",
        "item": {
          "@id": "https://www.df-alliance.com${path}"
        }
      }
    ]
  }
}
`;

export const webSchemas = {
  '/': (title: string, description: string, path: string) => oldSchema(title, description, path),
  '/services/trade-finance': () => `{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "@id": "https://www.df-alliance.com/services/trade-finance",
  "alternateName": "Trade Finance",
  "description": "Creating access to affordable finance for businesses of all sizes.",
  "image": "https://www.df-alliance.com/images/main/logisticsFinance/intro/1.png",
  "inLanguage": "English",
  "keywords": [
    "Trade Finance",
    "Commercial Finance"
  ],
  "isPartOf": "https://www.df-alliance.com/",
   "significantLink": "https://login.dpworld.com/auth/realms/DPWorld/protocol/openid-connect/auth?state=eyJ1dG1fc291cmNlIjoiIiwidXRtX21lZGl1bSI6IiIsInV0bV9jYW1wYWlnbiI6IiIsInBhZ2UtbmFtZSI6ImZpbmFuY2UiLCJidXR0b24tbmFtZSI6InZpc2l0LWNhcmdvZXMtZmluYW5jZSJ9&client_id=CF&response_type=code&redirect_uri=https://finance.dpworld.com/home/def",
  "sameAs": [
    "https://www.facebook.com/DigitalFreightAlliance_DPWorld",
    "https://twitter.com/D_F_Alliance",
    "https://www.linkedin.com/company/digital-freight-alliance"
  ],
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": {
          "@id": "https://www.df-alliance.com/"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Trade Finance",
        "item": {
          "@id": "https://www.df-alliance.com/services/trade-finance"
        }
      }
    ]
  }
}`,
  '/benefits/digital-tools/cargoes-flow': () => `{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "@id": "https://www.df-alliance.com/benefits/digital-tools/cargoes-flow",
  "alternateName": "CARGOES Flow",
  "description": "The container tracking tool that tracks ocean shipments across 100s of shipping lines, all in one easy to use interface. See the status of your shipments in real time.",
  "image": "https://www.df-alliance.com/images/pages/Services/CargoesFlow/header_ship.png",
  "inLanguage": "English",
  "keywords": [
    "Container Tracking",
    "Shipping Tracking"
  ],
  "isPartOf": "https://www.df-alliance.com/",
   "significantLink": "https://www.df-alliance.com/benefits/digital-tools/cargoes-flow#what-is",
  "sameAs": [
    "https://www.facebook.com/DigitalFreightAlliance_DPWorld",
    "https://twitter.com/D_F_Alliance",
    "https://www.linkedin.com/company/digital-freight-alliance"
  ],
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": {
          "@id": "https://www.df-alliance.com/"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Benefits",
        "item": {
          "@id": "https://www.df-alliance.com/benefits"
        }
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "Digital Tools",
        "item": {
          "@id": "https://www.df-alliance.com/benefits/digital-tools"
        }
      },
      {
        "@type": "ListItem",
        "position": 4,
        "name": "CARGOES Flow",
        "item": {
          "@id": "https://www.df-alliance.com/benefits/digital-tools/cargoes-flow"
        }
      }
    ]
  }
}`,
  '/benefits/digital-tools/tracking-system': () => `{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "@id": "https://www.df-alliance.com/benefits/digital-tools/tracking-system",
  "alternateName": "Tracking System",
  "description": "The ultimate cargo tracking tool that works on any desktop or mobile device. Let your customers know where their cargo is and view it on the map without having to call you! Get access to tracking data from more than 160 shipping carriers.",
  "image": "https://www.df-alliance.com/images/pages/Services/TrackingSystem/safari_white.svg",
  "inLanguage": "English",
  "keywords": [
    "Cargo Tracking System",
    "Shipping Tracking System"
  ],
  "isPartOf": "https://www.df-alliance.com/",
  "sameAs": [
    "https://www.facebook.com/DigitalFreightAlliance_DPWorld",
    "https://twitter.com/D_F_Alliance",
    "https://www.linkedin.com/company/digital-freight-alliance"
  ],
  "breadcrumb": {
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": {
          "@id": "https://www.df-alliance.com/"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Benefits",
        "item": {
          "@id": "https://www.df-alliance.com/benefits"
        }
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "Digital Tools",
        "item": {
          "@id": "https://www.df-alliance.com/benefits/digital-tools"
        }
      },
      {
        "@type": "ListItem",
        "position": 4,
        "name": "Tracking System",
        "item": {
          "@id": "https://www.df-alliance.com/benefits/digital-tools/tracking-system"
        }
      }
    ]
  }
}`,
  '/benefits/digital-tools/searates-erp': () => `{
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://www.df-alliance.com/benefits/digital-tools/searates-erp",
    "alternateName": "SeaRates ERP",
    "description": "Know about SeaRates ERP.",
    "image": "https://www.df-alliance.com/images/pages/Services/SeaRatesERP/section_1_main.svg",
    "inLanguage": "English",
    "keywords": [
      "SeaRates ERP"
    ],
    "isPartOf": "https://www.df-alliance.com/",
    "sameAs": [
      "https://www.facebook.com/DigitalFreightAlliance_DPWorld",
      "https://twitter.com/D_F_Alliance",
      "https://www.linkedin.com/company/digital-freight-alliance"
    ],
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": {
            "@id": "https://www.df-alliance.com/"
          }
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Benefits",
          "item": {
            "@id": "https://www.df-alliance.com/benefits"
          }
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": "Digital Tools",
          "item": {
            "@id": "https://www.df-alliance.com/benefits/digital-tools"
          }
        },
        {
          "@type": "ListItem",
          "position": 4,
          "name": "SeaRates ERP",
          "item": {
            "@id": "https://www.df-alliance.com/benefits/digital-tools/searates-erp"
          }
        }
      ]
    }
  }`,

  '/resources/terms-and-conditions': (title: string, description: string, path: string) =>
    newSchema(title, description, path, '', 'Terms and Conditions'),
  '/resources/privacy-policy': (title: string, description: string, path: string) =>
    newSchema(title, description, path, '', 'Privacy Policy'),
  '/resources/membership-regulations': (title: string, description: string, path: string) =>
    newSchema(
      title,
      description,
      path,
      '/images/main/members-regulations/members-regulations.svg',
      'Membership Regulations'
    ),
  '/resources/greetings': (title: string, description: string, path: string) =>
    newSchema(title, description, path, '', 'Greetings'),
  '/resources/code-of-ethics': (title: string, description: string, path: string) =>
    newSchema(title, description, path, '/images/main/codeOfEthic/code_of_ethics_bg.png', 'Code of Ethics'),
  '/resources/blacklist': (title: string, description: string, path: string) =>
    newSchema(title, description, path, '', 'Blacklist'),
  '/resources/bill-of-lading': (title: string, description: string, path: string) =>
    newSchema(title, description, path, '/images/pages/Resources/BillOfLading/mobile/hero-212.png', 'Bill of Lading'),
  '/resources/demurrage-and-detention': (title: string, description: string, path: string) =>
    newSchema(
      title,
      description,
      path,
      '/images/pages/Resources/DemurrageAndDetention/mobile/hero-360.png',
      'Demurrage and Detention'
    ),

  '/membership': (title: string, description: string, path: string) =>
    newSchema(title, description, path, '', 'Membership'),

  '/feedback/experience-survey': (title: string, description: string, path: string) =>
    newSchema(title, description, path, '', 'Experience Survey'),
  '/feedback/expectations-survey': (title: string, description: string, path: string) =>
    newSchema(title, description, path, '', 'Expectations Survey'),

  '/company/vision-strategy': (title: string, description: string, path: string) =>
    newSchema(title, description, path, '/images/pages/Company/VisionStrategy/1.png', 'Vision Strategy'),
  '/company/help-center': (title: string, description: string, path: string) =>
    newSchema(title, description, path, '', 'Help Center'),
  '/company/contact-us': (title: string, description: string, path: string) =>
    newSchema(title, description, path, '', 'Contact Us'),

  '/benefits/support': (title: string, description: string, path: string) =>
    newSchema(title, description, path, '/images/main/support/image.png', 'Support'),
  '/benefits/shipping-leads': (title: string, description: string, path: string) =>
    newSchema(title, description, path, '/images/main/your-rates/bg-md.jpg', 'Shipping Leads'),
  '/benefits/payment-protection': (title: string, description: string, path: string) =>
    newSchema(title, description, path, '/images/main/your-rates/bg-md.jpg', 'Payment Protection'),
  '/benefits/networking': (title: string, description: string, path: string) =>
    newSchema(title, description, path, '/images/main/freight-forwarders/image.png', 'Networking'),
  '/benefits/digital-tools': (title: string, description: string, path: string) =>
    newSchema(title, description, path, '/images/pages/Benefits/DigitalTools/header_background.png', 'Digital Tools'),
  '/benefits/digital-solution': (title: string, description: string, path: string) =>
    newSchema(title, description, path, '/images/main/digital-solution/image.png', 'Digital Solution'),
  '/benefits/brand-reliability': (title: string, description: string, path: string) =>
    newSchema(title, description, path, '/images/main/service-reliability/image.png', 'Brand Reliability'),

  '/freight-dictionary': (title: string, description: string, path: string) =>
    FreightDictionarySchema(title, description, path, '', 'Freight Dictionary'),

  freightDictionaryChildren: (params: FreightChildrenPagesParams) => FreightDictionaryChildrenSchema(params),

  '/freight-forwarder': (title: string, description: string, path: string) =>
    FreightForwarderSchema(title, description, path, '', 'Freight Forwarder'),

  freightForwarderChildren: (params: FreightChildrenPagesParams) => FreightForwarderChildrenSchema(params),

  post: (params: TPostSchema) => postSchema(params),
};
